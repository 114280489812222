<template>
    <v-card class="pa-6 pb-16 page-card">
        <div
            v-if="loading"
            class="d-flex justify-center"
        >
            <v-progress-circular
                size="48"
                indeterminate
            />
        </div>
        <div v-else>
            <h3 class="title mb-4">
                Asset Publication
            </h3>

            <!--            <v-row>
                <v-col
                    v-for="(kind, i) of kinds"
                    :key="i"
                    cols="6"
                    lg="2"
                    sm="4"
                    class="d-flex flex-column type-block text-center"
                    :class="{ 'm-active': kind.value === selectedKind,
                              'disabled': ![saleKinds.LISTING_SALE, saleKinds.ENGLISH_AUCTION].includes(kind.value)
                                  || disableChangeSaleType }">
                    <v-btn
                        x-large
                        text
                        class="mb-3 mx-auto border-grey kind-icon"
                        height="85px"
                        width="85px"
                        :disabled="![saleKinds.LISTING_SALE, saleKinds.ENGLISH_AUCTION].includes(kind.value)
                            || disableChangeSaleType"
                        @click="changeKind(kind.value)">
                        <img
                            :src="`/static/sale-type-icons/${kind.icon}`"
                            width="30px"
                            height="30px"
                            alt="">
                    </v-btn>
                    <p class="text-small">
                        {{ kind.title | uppercase }}
                    </p>
                </v-col>
            </v-row>-->
            <component
                :is="currentForm"
                :sale-object="asset.sale"
                :in-edit-mode="inEditMode"
                :show-actions="showActions"
                @formSubmitted="onFormSubmit"
                @stopSale="stopSale"
            >
                <v-textarea
                    v-model="auctionDescription"
                    counter="2000"
                    :rules="[v => v.length <= 2000 || 'Max 2000 characters']"
                    :disabled="!showActions"
                    label="Terms of Sale"
                    class="mb-5"
                />
            </component>
        </div>
        <AppOverlaySpinner v-if="loaderWithOverlay" />
    </v-card>
</template>

<script>
import assetApi from '@/services/apiCalls/asset';
import AppOverlaySpinner from '@/components/App/AppOverlaySpinner';
import ListingSaleForm from '@/components/Asset/AssetPublicationForms/ListingSaleForm';
import EnglishAuctionForm from '@/components/Asset/AssetPublicationForms/EnglishAuctionForm';
import { saleKinds, getSwitchStatusMutation } from '@/constants/saleKinds';
import { saleStatuses } from '@/constants/saleStatuses';
import { DELETED } from '@/utils/filters';

export default {
    components: {
        AppOverlaySpinner,
        ListingSaleForm,
        EnglishAuctionForm,
    },
    data() {
        return {
            inEditMode: this.$route.name === 'edit-sale',
            saleKinds,
            loading: false,
            selectedKind: '',
            auctionDescription: '',
            kinds: [
                {
                    title: 'Traditional Listing',
                    value: saleKinds.LISTING_SALE,
                    form: 'ListingSaleForm',
                    action: 'createListingSale',
                    icon: 'listing.svg',
                },
                {
                    title: 'English Auction',
                    value: saleKinds.ENGLISH_AUCTION,
                    form: 'EnglishAuctionForm',
                    action: 'createEnglishAuction',
                    editAction: 'editAuction',
                    icon: 'english.svg',
                },
                {
                    title: 'Hybrid Auction',
                    value: 'hybrid',
                    icon: 'hybrid.svg',
                },
                {
                    title: 'Multi Parcel Auction',
                    value: 'multi-parcel',
                    icon: 'multi_parcel.svg',
                },
                {
                    title: 'Direct Offer',
                    value: 'direct',
                    icon: 'direct.svg',
                },
                {
                    title: 'Live Auction',
                    value: 'live',
                    icon: 'live.svg',
                },
            ],
            asset: null,
            loaderWithOverlay: false,
        };
    },
    computed: {
        currentForm() {
            return this.kinds.find((k) => k.value === this.selectedKind)?.form;
        },
        formAction() {
            return this.kinds.find((k) => k.value === this.selectedKind).action;
        },
        editAction() {
            return this.kinds.find((k) => k.value === this.selectedKind).editAction;
        },
        disableChangeSaleType() {
            return this.inEditMode;
        },
        showActions() {
            return (
                (this.asset.status !== DELETED &&
                    ![saleStatuses.COMPLETE, saleStatuses.CANCELED].includes(this.asset?.sale?.status)) ||
                !this.inEditMode
            );
        },
    },
    async created() {
        this.selectedKind = this.kinds[1].value;
        if (this.$route.params.id) {
            try {
                this.loading = true;
                const { id, kind } = this.$route.params;

                const { data } = await assetApi.getAssetShortInfo(id, kind);
                this.asset = data[`get${kind}Asset`];

                if (this.asset.sale && this.inEditMode) {
                    this.selectedKind = this.asset.sale.kind;
                    this.auctionDescription = this.asset.sale[this.asset.sale.kind].details || '';
                }
            } catch (e) {
                this.$store.dispatch('handleError', e);

                this.$router.push('/assets?tab=Active');
            } finally {
                this.loading = false;
            }
        }
    },
    methods: {
        async publishAsset(data) {
            if (this.inEditMode && this.asset.sale && this.asset.sale.kind !== this.selectedKind) return;
            try {
                this.loaderWithOverlay = true;
                if (this.selectedKind !== saleKinds.LISTING_SALE) {
                    data[this.selectedKind].details = this.auctionDescription;
                }
                if (this.editAction && this.selectedKind === this.asset.sale?.kind && this.inEditMode) {
                    const payload = {
                        id: this.asset.sale.id,
                        ...data,
                    };
                    await this.$store.dispatch(`Asset/${this.editAction}`, payload);
                } else {
                    const payload = {
                        assetId: this.asset.id,
                        ...data,
                    };
                    await this.$store.dispatch(`Asset/${this.formAction}`, payload);
                }
                await this.$router.push('/assets?tab=Active');
            } catch (e) {
                this.$store.dispatch('handleError', e);
            } finally {
                this.loaderWithOverlay = false;
            }
        },
        onFormSubmit(data) {
            this.publishAsset(data);
        },
        async stopSale() {
            const mutation = getSwitchStatusMutation(this.asset.sale.kind);
            try {
                this.loaderWithOverlay = true;
                await assetApi[mutation](this.asset.sale.id, saleStatuses.CANCELED);

                this.$store.commit('snackbar', {
                    text: 'Asset sale was stopped successfully',
                    color: 'success',
                    isOpened: true,
                });
                this.$router.push('/assets?tab=Inactive');
            } catch (e) {
                this.$store.dispatch('handleError', e);
            } finally {
                this.loaderWithOverlay = false;
            }
        },
        changeKind(value) {
            this.selectedKind = value;
            this.auctionDescription = '';
        },
    },
};
</script>

<style scoped lang="scss">
.type-block {
    opacity: 0.6;
    &.disabled {
        opacity: 0.3;
    }

    &.m-active {
        opacity: 1;

        .v-btn {
            border-bottom: 2px solid #000;
        }
    }
}

.page-card {
    position: relative;
}
.type-wrapper {
    border-radius: 5px;
    border: 3px solid #000000;
    background-color: #a9a9a9;
    color: #ffffff;
    text-transform: uppercase;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
    cursor: pointer;

    &.active {
        background-color: #057301;
        border: 3px solid #08e800;
    }
}
</style>