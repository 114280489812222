<template>
    <div
        class="d-flex"
        :class="[!hideStopSale ? 'justify-space-between' : 'justify-end']"
    >
        <v-btn
            v-if="!hideStopSale"
            color="red"
            class="white--text"
            data-jestid="stop-sale-btn"
            @click="$emit('stopSale')"
        >
            Stop sale
        </v-btn>
        <div>
            <v-btn
                text
                class="mr-3"
                @click="$router.go(-1)"
            >
                Cancel
            </v-btn>
            <v-btn
                color="black"
                class="white--text"
                data-jestid="submit-btn"
                :disabled="!valid"
                @click="$emit('submit')"
            >
                {{ inEditMode ? 'Update' : 'Publish' }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { saleStatuses } from '@/constants/saleStatuses';

export default {
    props: {
        inEditMode: Boolean,
        saleObject: Object,
        valid: Boolean,
    },
    computed: {
        hideStopSale() {
            return (
                !this.saleObject ||
                [saleStatuses.CANCELED, saleStatuses.COMPLETE].includes(this.saleObject.status) ||
                !this.inEditMode
            );
        },
    },
};
</script>