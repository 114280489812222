<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <v-row class="mb-5">
            <v-col
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model.number="listingSale.listingPrice"
                    :disabled="!showActions"
                    label="Listing Price"
                    type="number"
                    prefix="$"
                    :rules="rules"
                />
            </v-col>
        </v-row>
        <FormActions
            v-if="showActions"
            :in-edit-mode="inEditMode"
            :valid="valid"
            :sale-object="saleObject"
            @submit="onSubmit"
            @stopSale="$emit('stopSale')"
        />
    </v-form>
</template>

<script>
import FormActions from '@/components/Asset/AssetPublicationForms/FormActions';

export default {
    components: {
        FormActions,
    },
    props: {
        saleObject: {
            type: Object,
            required: false,
        },
        inEditMode: {
            type: Boolean,
        },
        showActions: Boolean,
    },
    data() {
        return {
            valid: true,
            rules: [(v) => /^\d*$/.test(v) || 'Price should include only digits'],
            listingSale: {
                listingPrice: '',
            },
        };
    },
    created() {
        if (!this.saleObject || !this.saleObject.listingSale || this.$route.name !== 'edit-sale') return;

        this.listingSale = { ...this.saleObject.listingSale };
    },
    methods: {
        onSubmit() {
            if (!this.$refs.form.validate()) return;
            this.$emit('formSubmitted', { listingSale: this.listingSale });
        },
    },
};
</script>