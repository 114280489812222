<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <v-row class="mb-2">
            <v-col
                cols="12"
                md="4"
            >
                <v-text-field
                    v-model.number="startingPrice"
                    prefix="$"
                    type="number"
                    label="Starting Price"
                    :disabled="afterStart || afterEnd || !showActions"
                    :rules="[rules.number, rules.requiredIfStartDate]"
                />

                <v-text-field
                    v-model.number="bidIncrement"
                    prefix="$"
                    type="number"
                    label="Bid Increment"
                    :disabled="afterStart || afterEnd || !showActions"
                    :rules="[rules.number]"
                />
            </v-col>

            <v-col
                cols="12"
                md="4"
            >
                <date-picker
                    v-model="range"
                    :columns="$screens({ default: 1, md: 2 })"
                    :rows="$screens({ default: 2, md: 1 })"
                    is-range
                >
                    <template #default="{ showPopover }">
                        <v-text-field
                            v-model="startDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            :disabled="afterStart || afterEnd || !showActions"
                            label="Start date"
                            @click="showPopover"
                        />
                    </template>
                </date-picker>
                <div
                    ref="startTimeContainer"
                    class="start-time-wrapper"
                >
                    <v-menu
                        ref="startTimeMenu"
                        v-model="startTimeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        content-class="start-time-menu"
                        :attach="$refs.startTimeContainer"
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startTime"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                :disabled="afterStart || afterEnd || !showActions"
                                :rules="[rules.requiredIfStartDate]"
                                type="time"
                                label="Start time"
                                v-on="on"
                            >
                                <template #append>
                                    <div class="user-timezone">
                                        {{ userTimezone }}
                                    </div>
                                </template>
                            </v-text-field>
                        </template>
                        <v-time-picker
                            v-if="startTimeMenu"
                            v-model="startTime"
                            full-width
                            @update:period="$refs.startTimeMenu.save(startTime)"
                        />
                    </v-menu>
                </div>
            </v-col>

            <v-col
                cols="12"
                md="4"
            >
                <date-picker
                    v-model="endDateModel"
                    :columns="$screens({ default: 1, md: !afterStart && !afterEnd ? 2 : 1 })"
                    :rows="$screens({ default: !afterStart && !afterEnd ? 2 : 1, md: 1 })"
                    :is-range="!afterStart && !afterEnd"
                >
                    <template #default="{ showPopover }">
                        <v-text-field
                            v-model="endDate"
                            prepend-icon="mdi-calendar"
                            readonly
                            :disabled="afterEnd || !showActions"
                            label="End date"
                            :rules="[rules.requiredIfStartDate]"
                            @click="showPopover"
                        />
                    </template>
                </date-picker>
                <div
                    ref="endTimeContainer"
                    class="end-time-wrapper"
                >
                    <v-menu
                        ref="endTimeMenu"
                        v-model="endTimeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        content-class="end-time-menu"
                        :attach="$refs.endTimeContainer"
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="endTime"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                label="End time"
                                type="time"
                                :disabled="afterEnd || !showActions"
                                :rules="[rules.requiredIfStartDate]"
                                v-on="on"
                            >
                                <template #append>
                                    <div class="user-timezone">
                                        {{ userTimezone }}
                                    </div>
                                </template>
                            </v-text-field>
                        </template>
                        <v-time-picker
                            v-if="endTimeMenu"
                            v-model="endTime"
                            full-width
                            @update:period="$refs.endTimeMenu.save(endTime)"
                        />
                    </v-menu>
                </div>
            </v-col>
        </v-row>
        <!--        <div class="d-flex justify-end mb-5">-->
        <!--            <v-switch-->
        <!--                v-model="backups"-->
        <!--                :disabled="!showActions"-->
        <!--                label="Take Backups" />-->
        <!--        </div>-->
        <slot />
        <FormActions
            v-if="showActions"
            :in-edit-mode="inEditMode"
            :valid="valid"
            :sale-object="saleObject"
            @submit="onSubmit"
            @stopSale="$emit('stopSale')"
        />
    </v-form>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import FormActions from '@/components/Asset/AssetPublicationForms/FormActions';

export default {
    components: {
        DatePicker,
        FormActions,
    },
    props: {
        saleObject: {
            type: Object,
        },
        inEditMode: {
            type: Boolean,
        },
        showActions: Boolean,
    },
    data() {
        return {
            valid: true,
            rules: {
                number: (v) => /^\d*$/.test(v) || 'This field should include only digits',
                requiredIfStartDate: (v) => (!!v && !!this.startDate) || !this.startDate || 'Field is required',
            },
            range: {
                start: null,
                end: null,
            },
            startingPrice: '',
            bidIncrement: '',
            startTime: '',
            startTimeMenu: false,
            endTime: '',
            endTimeMenu: false,
            backups: false,
            customBids: [],
            currentStart: '',
            currentEnd: '',
            userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
    },
    computed: {
        startDate() {
            const startDate = Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }).format(new Date(this.range.start));
            return this.range.start ? startDate : '';
        },
        endDate() {
            const endDate = Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }).format(new Date(this.range.end));
            return this.range.start ? endDate : '';
        },
        startDateTime() {
            return this.startDate && this.startTime ? `${this.startDate || ''} ${this.startTime || ''}`.trim() : '';
        },
        endDateTime() {
            return this.endDate && this.endTime ? `${this.endDate || ''} ${this.endTime || ''}`.trim() : '';
        },
        afterStart() {
            if (!this.currentStart || !this.currentEnd) return false;
            return new Date().getTime() > new Date(this.currentStart).getTime();
        },
        afterEnd() {
            if (!this.currentStart || !this.currentEnd) return false;
            return new Date().getTime() > new Date(this.currentEnd).getTime();
        },
        endDateModel: {
            get() {
                return !this.afterStart && !this.afterEnd ? this.range : this.range.end;
            },
            set(value) {
                if (!this.afterStart && !this.afterEnd) {
                    this.range = value;
                } else {
                    this.range.end = value;
                }
            },
        },
    },
    created() {
        const tzInterval = setInterval(() => {
            this.userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }, 1000 * 60);

        this.$once('hook:beforeDestroy', () => {
            clearInterval(tzInterval);
        });

        if (this.saleObject && this.saleObject.englishAuction && this.$route.name === 'edit-sale') {
            this.fillForm(this.saleObject.englishAuction);
        }
    },
    methods: {
        onSubmit() {
            if (!this.$refs.form.validate()) return;
            const startDate = this.startDateTime ? new Date(this.startDateTime).toISOString() : null;
            const endDate = this.endDateTime ? new Date(this.endDateTime).toISOString() : null;
            const startDateUnix = new Date(startDate).getTime();
            const endDateUnix = new Date(endDate).getTime();
            const toDate = new Date().getTime();

            if (startDate && endDate && endDateUnix <= startDateUnix) {
                this.$store.commit('snackbar', {
                    text: 'Time range is incorrect.',
                    color: 'error',
                    isOpened: true,
                });
                return;
            }

            if (startDateUnix < toDate && !this.afterStart) {
                this.$store.commit('snackbar', {
                    text: 'Start date should be in the future',
                    color: 'error',
                    isOpened: true,
                });
                return;
            }

            let data;
            if (this.afterEnd && this.afterStart) {
                data = {
                    englishAuction: {
                        backups: !!this.backups,
                    },
                };
            } else if (this.afterStart && !this.afterEnd) {
                data = {
                    englishAuction: {
                        endDate,
                        backups: !!this.backups,
                    },
                };
            } else {
                data = {
                    englishAuction: {
                        startingPrice: this.startingPrice || null,
                        bidIncrement: this.bidIncrement || null,
                        startDate,
                        endDate,
                        backups: !!this.backups,
                    },
                };
            }
            this.$emit('formSubmitted', data);
        },

        fillForm(object) {
            this.startingPrice = object.startingPrice;
            this.bidIncrement = object.bidIncrement;
            this.backups = object.backups;

            if (object.startDate) {
                this.range.start = new Date(object.startDate);
                this.startTime = Intl.DateTimeFormat('en-US', {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                }).format(new Date(object.startDate));
                this.currentStart = object.startDate;
            }

            if (object.endDate) {
                this.range.end = new Date(object.endDate);
                this.endTime = Intl.DateTimeFormat('en-US', {
                    hour12: false,
                    hour: 'numeric',
                    minute: 'numeric',
                }).format(new Date(object.endDate));
                this.currentEnd = object.endDate;
            }
        },
        updateDropdownValue(value) {
            this.bidIncrement = value;
        },
        addNewCustomValue(value) {
            this.customBids.unshift(value);
        },
    },
};
</script>

<style scoped lang="scss">
.start-time-wrapper,
.end-time-wrapper {
    position: relative;
}
.user-timezone {
    opacity: 0.6;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
}
</style>